@import '../../variables.scss';

.container {
  position: relative,
}

.header {
  font-size: 1.5em;
  margin-bottom: ($theme-spacing);
  position: relative;

  .hello {
    display: inline-block;
  }

  .logout {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 0.6em,
  }
}

.body {
  padding-top: ($theme-spacing) * 2;
  padding-bottom: ($theme-spacing) * 2;
  padding-left: ($theme-spacing) * 2;
  padding-right: ($theme-spacing) * 2;
  margin: (($theme-spacing) * 2) 0px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}

.methodName {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: ($theme-spacing);
}

.anotherMethodLink {
  text-align: center;
  font-size: (0.8em)
}

.buttonsContainer {
  text-align: center;
  margin: ($theme-spacing) 0;

  button {
    margin: ($theme-spacing) 0;
  }
}