@import '../../variables.scss';

.secretContainer {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  margin-top: ($theme-spacing) * 2;
  margin-bottom: ($theme-spacing) * 2;
}

.qrcodeContainer {
  text-align: center;
  padding: ($theme-spacing) * 2;
}

.base32Container {
  text-align: center;
  border-top: 1px solid #dcdcdc;
  padding: ($theme-spacing);
  word-wrap: break-word;
}

.otpauthContainer {
  display: none;
}

.text {
  text-align: center;
}

.needGoogleAuthenticator {
  text-align: center;
  margin-top: ($theme-spacing) * 2;
}

.needGoogleAuthenticatorText {
  font-size: 0.8em;
}

.store {
  width: 100px;
  margin-top: ($theme-spacing) * 0.5;
  margin-left: ($theme-spacing) * 0.5;
  margin-right: ($theme-spacing) * 0.5;
}

.buttonContainer {
  text-align: center;
  padding-top: ($theme-spacing) * 2;
}

.progressContainer {
  text-align: center;
  padding-top: ($theme-spacing) * 2,
}

.button {
  margin-left: ($theme-spacing);
  margin-right: ($theme-spacing);
}

.loginButtonContainer {
  text-align: center;
}