@import '../../variables.scss';

.form {
  margin-top: ($theme-spacing) * 2,
}

.field {
  width: 100%;
  margin-bottom: ($theme-spacing) * 2;
}

.buttonsContainer {
  width: 100%;
}

.buttonContainer {
  width: 50%;
  box-sizing: border-box;
  display: inline-block;
}

.buttonResetContainer {
  padding-right: ($theme-spacing) / 2;
}

.buttonCancelContainer {
  padding-left: ($theme-spacing) / 2;
}

.button {
  width: 100%;
}